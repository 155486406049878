.coin-container{
    margin-top: 10px;
}
.coin{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.coin>img{
    height: 70px;
    margin-left: 20px;
}
.coin-row{
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    justify-content: space-around;
    align-items: center;
}
.coin-data{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    font-weight: bold;
}


.red{
    color: #f00606;
}
.green{
    color: #11d811;
}


@media screen and (max-width: 768px) {
    .coin{
        display: flex;
        flex-direction: column;
    }
    .coin-row{
        display: flex;
        flex-direction: column;
    }
}